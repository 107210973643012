import { MenuEntry } from 'catgirl-uikit'

export const status = {
  LIVE: {
    text: "LIVE",
    color: "failure",
  },
  SOON: {
    text: "SOON",
    color: "#FFABC5",
  },
  NEW: {
    text: "NEW",
    color: "success",
  },
};

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://hub.catgirlcoin.com/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    initialOpenState: false,
    items: [
      {
        label: 'Catgirl Contract',
        href: 'https://bscscan.com/token/0x79ebc9a2ce02277a4b5b3a768b1c0a4ed75bd936',
        target: '_blank'
      },
      {
        label: 'Contract Audit',
        href: 'https://solidity.finance/audits/CatGirl/',
        target: '_blank'
      },
      {
        label: 'Catgirl Website',
        href: 'https://www.catgirl.io',
        target: '_blank'
      },
      {
        label: 'PooCoin Chart',
        href: 'https://poocoin.app/tokens/0x79ebc9a2ce02277a4b5b3a768b1c0a4ed75bd936',
        target: '_blank'
      },
      {
        label: 'PancakeSwap',
        href: 'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x79ebc9a2ce02277a4b5b3a768b1c0a4ed75bd936',
        target: '_blank'
      },
    ],
  },
]

export default config
