import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  width: 100%;
  padding: 32px 16px;

  background-image: url('/images/paw-pattern.svg');
  background-repeat: no-repeat;
  background-position: bottom -6px center;
  background-size: 50%;


  ${({ theme }) => theme.mediaQueries.xs} {
    background-size: auto;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    background-image: url('/images/catgirl-moon.png'), url('/images/catgirl-original.png'), url('/images/arch-${({ theme }) => (theme.isDark ? 'dark' : 'light')}.svg');
    background-repeat: no-repeat;
    background-position: 10% 230px, 90% 230px, center 420px;
    background-size: 376px,346px, contain;
    min-height: 90vh;
  }
`

export default Container
