import React, { useContext } from 'react'
import { Menu as UikitMenu} from 'catgirl-uikit'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetPriceData from 'hooks/useGetPriceData'
import useGetLocalProfile from 'hooks/useGetLocalProfile'
import useAuth from 'hooks/useAuth'
import links from './config'
import { CAKE } from '../../constants'


const StyledMenu = styled(UikitMenu)`
  color: red !important;
  width: 10px;
`;
const Menu: React.FC = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  // const priceData = useGetPriceData()
  // const cakePriceUsd = priceData ? Number(priceData.data[CAKE.address].price) : undefined
  const profile = useGetLocalProfile()

  return (
    <StyledMenu
      links={links}
      account={account as string}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={0}
      profile={profile}
      {...props}
    />
  )
}

export default Menu
